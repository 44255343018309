<template>
   <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" @click="onChangeType('partcreate')" class="tab-item" :class="{'active': type === 'partcreate'}">{{ $t('user.partCreate') }}</button>
        <button type="button" @click="onChangeType('partgroup')" class="tab-item" :class="{'active': type === 'partgroup'}">{{ $t('user.partGroupCreate') }}</button>
      </div>
   </div>
  <div class="allwrap min-w1400" v-if="type == 'partcreate'">
    <div>
      <article class="content_wrap">
        <table>
          <colgroup>
             <col width="18%">
             <col width="32%">
             <col width="18%">
             <col width="32%">
          </colgroup>
          <thead>
            <tr>
              <th colspan="4">{{ $t('user.option') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('user.isLogin') }}</td>
              <td><ui-toggle :textType="'default'" :checked="joinData.loginYn" @setYn="setloginYn(joinData.loginYn)" /></td>
              <td>{{ $t('user.isUserLock') }}</td>
              <td><ui-toggle :textType="'default'" :checked="joinData.memLockYn" @setYn="setMemlockYn(joinData.memLockYn)" /></td>
            </tr>
          </tbody>
        </table>
        <table>
          <colgroup>
             <col width="18%">
             <col width="32%">
             <col width="18%">
             <col width="32%">
          </colgroup>
          <thead>
            <tr>
              <th colspan="4">{{ $t('common.memberInfo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('common.id') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td><typing :type="'text'" v-model="joinData.memId" :value="joinData.memId" /></td>
              <td>{{ $t('common.password') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td><typing :type="'password'" v-model="joinData.memPass" /></td>
            </tr>
            <tr>
              <td>{{ $t('common.nickName') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td><typing :type="'text'" v-model="joinData.memNick" /></td>
              <td>{{ $t('table.head.supPartner') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                <select class="w_half" v-model="selectPartnerLevel">
                  <option v-for="item in partnerLevel" :value="item.code" :key="item.code">
                    {{item.codeName}}
                  </option>
                </select>
                <select class="w_half" v-model="joinData.recommenderId">
                  <template v-if="partnerMemId && partnerMemId.length > 0">
                    <option v-for="item in partnerMemId" :value="item.memId" :key="item.memId">
                      {{item.memId}}
                    </option>
                  </template>
                </select>
                <div class="idsch">
                  <typing :type="'text'" style="width: 100%;" :value="findRecommenderId" v-model="findRecommenderId" />
                  <a class="btn-layout btn-apply" @click="onFindRecommenderId">{{ $t('searchArea.sch') }}</a>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ $t('common.name') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                <typing :type="'text'" v-model="joinData.memName" />
              </td>
              <td>{{ $t('common.tel') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td><typing :type="'text'" v-model="joinData.memPhone" /></td>
            </tr>
            <tr>
              <td>{{ $t('common.bankAccount') }}</td>
              <td>
                <select class="reqMember w_full" v-model="joinData.bank" @change="setBankChange">
                  <option value="">{{$t('common.notSelect')}}</option>
                  <option v-for="item in bankList" :value="item.bankCode" :key="item.bankCode">{{ item.bankNameKr }}</option>
                </select>
                <template v-if="joinData.bank">
                  <typing :type="'text'" v-model="joinData.bankAcc" />
                </template>
              </td>
              <td style="white-space: nowrap;">{{ $t('common.cashPass') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                <typing :type="'text'" v-model="joinData.cashOutPass" />
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <colgroup>
            <col width="15%">
            <col width="15%">
            <col width="70%">
          </colgroup>
          <thead>
            <tr>
              <th colspan="3">{{ $t('table.head.rate') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="joinData.rateList">
              <template v-for="item in joinData.rateList" :key="item.category">
                <tr>
                  <td rowspan="2">{{ $t(`table.head.${item.category}`) }}</td>
                  <td>{{ $t('common.rolling') }}</td>
                  <td>
                    <div class="btnwrap">
                      <input type="number" class="w50"  v-model="item.pointRate"/>
                      <button type="button" class="btn-layout btn-range btn-max" @click="item.pointRate = item.maxPointRate">{{ $t('table.head.max') }}<i>({{ item.maxPointRate }})</i></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('common.losing') }}</td>
                  <td>
                    <div class="btnwrap">
                      <input type="number" class="w50" v-model="item.loseRate" />
                      <button type="button" class="btn-layout btn-range btn-max" @click="item.loseRate = item.maxLoseRate">{{ $t('table.head.max') }}<i>( {{item.maxLoseRate}} )</i></button>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>{{ $t('common.memo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <textarea v-model="joinData.memo"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="center"><btn-regist @click="regist" /></div>
      </article>
    </div>
  </div>
  <div class="allwrap min-w1400" v-if="type == 'partgroup'">
    <div>
      <article class="content_wrap">
        <table>
          <colgroup>
            <col width="18%">
            <col width="32%">
            <col width="18%">
            <col width="32%">
          </colgroup>
          <thead>
            <tr>
              <th colspan="4">{{ $t('user.option') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('user.isLogin') }}</td>
              <td><ui-toggle :textType="'default'" :checked="joinData.loginYn" @setYn="setloginYn(joinData.loginYn)" /></td>
              <td>{{ $t('user.isUserLock') }}</td>
              <td><ui-toggle :textType="'default'" :checked="joinData.memLockYn" @setYn="setMemlockYn(joinData.memLockYn)" /></td>
            </tr>
          </tbody>
        </table>
        <table>
          <colgroup>
             <col width="30%">
             <col width="70%">
          </colgroup>
          <thead>
            <tr>
              <th colspan="2">{{ $t('common.memberInfo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('common.id') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                 <typing :type="'text'" v-model="joinData.memId" :value="joinData.memId" />
                 <input type="text" pattern="[0-9]*" name="" v-model="groupMemIdStart" class="w33">~
                 <input type="text" pattern="[0-9]*" name="" v-model="groupMemIdEnd" class="w33">
              </td>
           </tr>
           <tr>
              <td>{{ $t('common.password') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td><typing :type="'password'" v-model="joinData.memPass" /></td>
            </tr>
            <tr>
              <td>{{ $t('common.nickName') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                 <typing :type="'text'" v-model="joinData.memNick" />
                 <input type="text" pattern="[0-9]*" name="" v-model="groupMemIdStart" disabled class="w33">~
                 <input type="text" pattern="[0-9]*" name="" v-model="groupMemIdEnd" disabled class="w33">
              </td>
           </tr>
           <tr>
              <td>{{ $t('table.head.supPartner') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                <select class="w_half" v-model="selectPartnerLevel">
                  <option v-for="item in partnerLevel" :value="item.code" :key="item.code">
                    {{item.codeName}}
                  </option>
                </select>
                <select class="w_half" v-model="joinData.recommenderId">
                  <template v-if="partnerMemId && partnerMemId.length > 0">
                    <option v-for="item in partnerMemId" :value="item.memId" :key="item.memId">
                      {{item.memId}}
                    </option>
                  </template>
                </select>
                <typing :type="'text'" :class="'ml-5'" :value="findRecommenderId" v-model="findRecommenderId"/>
                <a class="btn-layout btn-apply" @click="onFindRecommenderId">{{ $t('searchArea.sch') }}</a>
              </td>
            </tr>
            <tr>
              <td>{{ $t('common.name') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                <typing :type="'text'" v-model="joinData.memName" />
                <input type="text" pattern="[0-9]*" name="" v-model="groupMemIdStart" disabled class="w33">~
                <input type="text" pattern="[0-9]*" name="" v-model="groupMemIdEnd" disabled class="w33">
              </td>
            </tr>
            <tr>
              <td>{{ $t('common.tel') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td><typing :type="'text'" v-model="joinData.memPhone" /></td>
            </tr>
            <tr>
              <td>{{ $t('common.bankAccount') }}</td>
              <td>
                <select class="reqMember w_full" v-model="joinData.bank" @change="setBankChange">
                  <option value="">{{$t('common.notSelect')}}</option>
                  <option v-for="item in bankList" :value="item.bankCode" :key="item.bankCode">{{ item.bankNameKr }}</option>
                </select>
                <template v-if="joinData.bank">
                  <typing :type="'text'" v-model="joinData.bankAcc" />
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t('common.cashPass') }}<em class="fc-rd fs-1">({{ $t('common.essent') }})</em></td>
              <td>
                <typing :type="'text'" v-model="joinData.cashOutPass" />
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <colgroup>
            <col width="15%">
            <col width="15%">
            <col width="70%">
          </colgroup>
          <thead>
            <tr>
              <th colspan="3">{{ $t('table.head.rate') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="joinData.rateList">
              <template v-for="item in joinData.rateList" :key="item.category">
                <tr>
                  <td rowspan="2">{{ $t(`table.head.${item.category}`) }}</td>
                  <td>{{ $t('common.rolling') }}</td>
                  <td>
                    <div class="btnwrap">
                      <input type="number" class="w50"  v-model="item.pointRate"/>
                      <button type="button" class="btn-layout btn-range btn-max" @click="item.pointRate = item.maxPointRate">{{ $t('table.head.max') }}<i>({{ item.maxPointRate }})</i></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('common.losing') }}</td>
                  <td>
                    <div class="btnwrap">
                      <input type="number" class="w50" v-model="item.loseRate" />
                      <button type="button" class="btn-layout btn-range btn-max" @click="item.loseRate = item.maxLoseRate">{{ $t('table.head.max') }}<i>( {{item.maxLoseRate}} )</i></button>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>{{ $t('common.memo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <textarea v-model="joinData.memo"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="center"><btn-regist @click="registGroup" /></div>
      </article>
    </div>
  </div>
<!--  <CreateAlert :list="groupListChecker"/>-->
</template>

<script>
import lodash from 'lodash'
import { getSiteData } from '@/libs/auth-helper'
import subTitle from '@/components/main/PageSubTitle'
import typing from '@/components/ui/Typing'
import uiCheck from '@/components/ui/UiCheck'
import uiToggle from '@/components/ui/UiToggle'
import BtnRegist from '@/components/ui/BtnRegist'
import CreateAlert from '@/components/ui/CreateAlert'
import { getCode, memberJoin, memberDetail, comBank, getUserTop } from '@/api/member'
import { RATE_MODEL } from '@/libs/constants.js'
import UiCheck from '@/components/ui/UiCheck.vue'
import { getMaxRateByRecommendId, partnerDetail, partnerJoin, partnerLevels, partnerMemIds } from '@/api/partner'
import { isValidUserId, isValidPassword } from '@/libs/utils'
import { adminPartnerSearch, adminTopRate } from '@/api/admin'

export default {
  name: 'userCreate',
  components: {
    subTitle,
    typing,
    uiToggle,
    BtnRegist,
    CreateAlert
  },
  data () {
    return {
      findRecommenderId: '',
      isAddGroupComplete: false,
      groupListChecker: [],
      type: 'partcreate',
      groupMemIdStart: '',
      groupMemIdEnd: '',
      minibox: false,
      setRate: 'casino',
      checkedToggle: false,
      subToggle: false,
      siteInfo: {},
      commissionDisplay: 'all',
      commissionObj: {
        group: [],
        commissionList: []
      },
      bankList: [],
      rateTypeDefault: '',
      rateType: [],
      betLimitList: [],
      joinData: {
        memIdList: [],
        memNickList: [],
        memNameList: [],
        siteId: '',
        memId: '',
        memPass: '',
        memNick: '',
        memName: '',
        memPhone: '',
        recommenderId: '',
        cashOutPass: '',
        memLevel: '1',
        sportsLevel: '1',
        casinoLevel: '1',
        slotLevel: '1',
        mgLevel: '1',
        loginYn: true,
        betYn: 'N',
        recommendYn: true,
        memLockYn: false,
        memBlackYn: false,
        memStatus: '1',
        bank: '',
        bankAcc: '',
        memo: '',
        msgTitle: '',
        msgContent: '',
        cashSendYn: 'Y',
        cashReceiveYn: 'N',
        rateList: [
          { category: 'casino', maxPointRate: '0.0', pointRate: '0.0', maxLoseRate: '0.0', loseRate: '0.0' },
          { category: 'slot', maxPointRate: '0.0', pointRate: '0.0', maxLoseRate: '0.0', loseRate: '0.0' }
        ]
      },
      rateList: [],
      upperRate: [],
      originRate: {},
      levels: '',
      batchRate_point: '0.00',
      batchRate_lose: '0.00',
      rateGroup: {
        etc1: '',
        etc2: '',
        etc3: ''
      },
      partnerLevel: [],
      partnerMemId: [],
      selectPartnerLevel: '',
      groupList: [],
      groupListCheck: false
    }
  },
  methods: {
    onFindRecommenderId () {
      const params = {
        memId: this.findRecommenderId
      }
      adminPartnerSearch(params).then(res => {
        console.log(res)
        const result = res
        if (result.resultCode === '0') {
          const data = result.data.partnerMemId
          if (data) {
            this.partnerMemId = []
            this.selectPartnerLevel = data.partnerLevel
          } else {
            alert('없는 상위회원입니다.')
            this.selectPartnerLevel = ''
            this.joinData.recommenderId = ''
          }
        }
      })
    },
    getTopRate () {
      const params = {
        memId: this.joinData.recommenderId
      }

      adminTopRate(params).then(res => {
        console.log('adminTopRate : ', params, res)
        const result = res
        if (result.resultCode === '0') {
          const data = result.data.topRate
          if (data) {
            data.forEach(item => {
              item.maxPointRate = item.pointRate
              item.maxLoseRate = item.loseRate
              item.pointRate = '0.0'
              item.loseRate = '0.0'
            })

            this.joinData.rateList = data
          }
          console.log(this.joinData.rateList)
        }
      })
    },
    onChangeType (type) {
      this.type = type
    },
    async groupMemIdCheck (startValue, endValue) {
      this.groupList = []
      const start = Number(startValue)
      const end = Number(endValue)
      if (!this.joinData.memId) {
        alert('아이디를 입력해주세요')
        this.groupListCheck = false
        return false
      }

      // const isMemIdValid = await this.memIdCheck(this.joinData.memId)
      // if (!isMemIdValid) {
      //   this.groupListCheck = false
      //   return false
      // }

      this.groupListCheck = true

      this.groupListCheck = this.groupCheck(start, end)

      for (let i = start; i <= end; i++) {
        if (i < 10) {
          if (i === start) {
            this.groupMemIdStart = '0' + i.toString()
          }
          if (i === end) {
            this.groupMemIdEnd = '0' + i.toString()
          }
          this.groupList.push('0' + i.toString())
        } else {
          this.groupList.push(i.toString())
        }
      }
      console.log(this.groupList)
      if (this.groupListCheck) {
        return true
      }
      return false
    },
    groupCheck (startValue, endValue) {
      const start = parseInt(startValue)
      const end = parseInt(endValue)
      console.log(start, end)

      if (isNaN(start) || isNaN(end)) {
        alert('숫자만 입력해주세요')
        return false
      }

      if (start >= end) {
        alert('뒷자리가 앞자리보다 커야 합니다.')
        return false
      }

      if ((end - start) > 49) {
        alert('최대 50명까지 가입이 가능합니다.')
        return false
      }
      return true
    },
    minibtn () {
      this.minibox = !this.minibox
    },
    changeTabRate (setRate) {
      this.setRate = setRate
    },
    async getPartnerMemIds () {
      const params = {
        code: this.selectPartnerLevel
      }
      const res = await partnerMemIds(params)
      this.partnerMemId = res.data.partnerMemIds
      this.joinData.recommenderId = this.findRecommenderId ? this.findRecommenderId : this.partnerMemId[0].memId
    },
    totalRateLimit (type) {
      let controlRate = 0
      if (type === 'point') {
        controlRate = this.batchRate_point
      } else if (type === 'lose') {
        controlRate = this.batchRate_lose
      }
      const n = Number(controlRate).toFixed(2)
      if (n >= 100) {
        controlRate = (100).toFixed(2)
      } else if (n < 0) {
        controlRate = (0).toFixed(2)
      } else {
        controlRate = Number(controlRate).toFixed(2)
      }
    },
    rateAllChange (type, groupCode) {
      let controlRate = '0.00'
      let rateList = []
      if (type === 'point') {
        controlRate = this.batchRate_point
        rateList = this.rateList
      } else if (type === 'insured') {
        controlRate = this.batchRate_insured
        rateList = this.insuredRateList
      } else if (type === 'lose') {
        controlRate = this.batchRate_lose
        rateList = this.rateList
      }

      for (const item of rateList) {
        let limit
        if (this.selectPartnerLevel !== 'AUTO') {
          if (type === 'point') {
            limit = Number(item.maxPointRate)
          } else if (type === 'insured') {
            limit = Number(item.maxRate)
          } else if (type === 'lose') {
            limit = Number(item.maxLoseRate)
          }
        }

        const controlRateNumber = Number(controlRate)
        if (controlRateNumber > limit) {
          if (!groupCode || item.groupCode === groupCode) {
            if (type === 'lose') {
              item.loseRate = limit.toFixed(2)
            } else {
              item.pointRate = limit.toFixed(2)
            }
          }
        } else {
          if (!groupCode || item.groupCode === groupCode) {
            if (type === 'lose') {
              item.loseRate = controlRateNumber.toFixed(2)
            } else {
              item.pointRate = controlRateNumber.toFixed(2)
            }
          }
        }
      }
    },
    async getLevels () {
      const data = await getCode('memLevel')
      this.levels = data
    },
    async getBank () {
      const req = {
        siteId: this.siteInfo.siteId
      }
      const res = await comBank(req)
      this.bankList = res.data.list
    },
    async requireCheck () {
      const requiredList = ['memId', 'memNick', 'memName', 'memPass', 'cashOutPass', 'recommenderId', 'memPhone']
      for (const key of requiredList) {
        if (!this.joinData[key]) {
          this.requireAlert(key)
          return false
        }
      }
      return true
    },
    async memIdCheck () {
      const reqData = {
        memId: this.joinData.memId
      }
      const res = await partnerDetail(reqData)
      if (res.resultCode === '0') {
        if (res.data.member.memId) {
          alert('중복된 회원입니다. 다시 입력해주세요.')
          this.joinData.memId = ''
          return false
        }
      } else {
        return true
      }
    },
    requireAlert (key) {
      let text = ''
      switch (key) {
        case 'memId':
          text = '아이디를'
          break
        case 'memNick':
          text = '닉네임을'
          break
        case 'recommenderId':
          text = '상위회원을'
          break
        case 'memName':
          text = '이름을'
          break
        case 'memPass':
          text = '비밀번호를'
          break
        case 'cashOutPass':
          text = '출금비밀번호를'
          break
        case 'memPhone':
          text = '전화번호를'
          break
      }
      if (text) {
        alert(`${text} 입력해주세요.`)
      }
    },
    rateLimit (idx, maxRate) {
      const limit = Number(maxRate)
      const n = Number(this.rateList[idx].pointRate).toFixed(2)
      if (n >= limit) {
        this.rateList[idx].pointRate = limit.toFixed(2)
      } else if (n < 0) {
        this.rateList[idx].pointRate = (0).toFixed(2)
      }
    },
    increase: function (idx, maxRate) {
      const limit = Number(maxRate)
      if (Number(this.rateList[idx].pointRate) === limit) {
        return
      }
      this.rateList[idx].pointRate = (Number(this.rateList[idx].pointRate) + 0.05).toFixed(2)
    },
    decrease: function (idx) {
      if (Number(this.rateList.pointRate) <= 0) {
        return
      }
      this.rateList[idx].pointRate = (Number(this.rateList[idx].pointRate) - 0.05).toFixed(2)
    },
    increaseLose: function (idx, maxRate) {
      const limit = Number(maxRate)
      if (Number(this.rateList[idx].loseRate) === limit) {
        return
      }
      this.rateList[idx].loseRate = (Number(this.rateList[idx].loseRate) + 0.05).toFixed(2)
    },
    decreaseLost: function (idx) {
      if (Number(this.rateList.loseRate) <= 0) {
        return
      }
      this.rateList[idx].loseRate = (Number(this.rateList[idx].loseRate) - 0.05).toFixed(2)
    },
    setBankChange () {
      if (this.joinData.bank === '') {
        this.joinData.bankAcc === ''
      }
    },
    setloginYn (check) {
      this.joinData.loginYn = !check
    },
    setBetYn (check) {
      this.joinData.betYn = !check
    },
    setRecommendYn (check) {
      this.joinData.recommendYn = !check
    },
    setMemlockYn (check) {
      this.joinData.memLockYn = !check
    },
    switchToggle (event) {
      const toggle = event.target
      const text = toggle.previousElementSibling
      // // console.log(toggle.checked)
      if (text) {
        if (toggle.checked) {
          text.classList.remove('off')
          text.classList.add('on')
        } else {
          text.classList.remove('on')
          text.classList.add('off')
        }

        this.groupListChecker.push({
          memId,
          success
        })
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async registGroup () {
      const isGroup = await this.groupMemIdCheck(this.groupMemIdStart, this.groupMemIdEnd)
      if (!isGroup) {
        return false
      }

      const requireCheck = await this.requireCheck()
      if (!requireCheck) {
        return false
      }

      let success = true

      const memIdList = []
      const memNickList = []
      const memNameList = []

      for (const extend of this.groupList) {
        if (success) {
          const memId = this.joinData.memId + extend
          if (memId.length > 20 || memId.length < 3) {
            alert('파트너아이디는 최소 3자이상 최대 20 자이하로 작성해주세요.')
            // this.emitter.emit('Loading', true)
            success = false
          }

          if (!isValidUserId(memId)) {
            alert('파트너아이디는 최소 3자이상 최대 20 자이하로 영문+숫자로 작성해주세요.')
            // this.emitter.emit('Loading', true)
            success = false
          }

          if (success) {
            memIdList.push(this.joinData.memId + extend)
            memNameList.push(this.joinData.memName + extend)
            memNickList.push(this.joinData.memNick + extend)
          }
        }
      }

      if (success) {
        const reqData = {
          ...this.joinData,
          memIdList,
          memNameList,
          memNickList
        }
        for (const key in reqData) {
          if (typeof reqData[key] === 'boolean') {
            const yn = reqData[key]
            if (yn) {
              reqData[key] = 'Y'
            } else {
              reqData[key] = 'N'
            }
          }
        }

        delete reqData.memId
        delete reqData.memNick
        delete reqData.memName
        console.log(reqData)

        this.emitter.emit('Loading', true)
        const res = await partnerJoin(reqData)
        console.log(res)
        this.emitter.emit('Loading', false)
        if (res.resultCode === '0') {
          alert('파트너등록완료')
          location.reload()
        } else {
          alert(res.resultMessage)
        }
      }
    },
    async regist () {
      const requireCheck = this.requireCheck()
      if (!requireCheck) {
        return
      }
      if (this.joinData.memId.length > 20 || this.joinData.memId.length < 3) {
        alert('파트너아이디는 최소 3자이상 최대 20 자이하로 작성해주세요.')
        return
      }

      if (!isValidUserId(this.joinData.memId)) {
        alert('파트너아이디는 최소 3자이상 최대 20 자이하로 영문+숫자로 작성해주세요.')
        return
      }

      if (!isValidPassword(this.joinData.memPass)) {
        alert('비밀번호를 다시 입력해주세요.')
        return
      }

      const memIdCheck = await this.memIdCheck()
      if (!memIdCheck) {
        return
      }

      const reqData = { ...this.joinData }
      for (const key in reqData) {
        if (typeof reqData[key] === 'boolean') {
          const yn = reqData[key]
          if (yn) {
            reqData[key] = 'Y'
          } else {
            reqData[key] = 'N'
          }
        }
      }
      reqData.memIdList.push(reqData.memId)
      reqData.memNickList.push(reqData.memNick)
      reqData.memNameList.push(reqData.memName)

      delete reqData.memId
      delete reqData.memNick
      delete reqData.memName
      console.log(reqData)

      this.emitter.emit('Loading', true)
      const res = await partnerJoin(reqData)
      console.log(res)
      this.emitter.emit('Loading', false)
      if (res.resultCode === '0') {
        alert('파트너등록완료')
        location.reload()
      } else {
        alert(res.resultMessage)
      }
    },
    showHidden (event) {
      const target = event.target
      // console.log(target);
      this.checkedToggle = target.checked
      // console.log(this.checkedToggle);
    },
    hideHidden (event) {
      const target = event.target
      // console.log(target);
      this.checkedToggle = !target.checked
      // console.log(this.checkedToggle);
    },
    subContentsOpen (event) {
      let target, icon
      for (const loop of event.path) {
        if (loop.classList.contains('subTitle')) {
          target = loop.nextElementSibling
          break
        } else if (loop.classList.contains('btn-subConShow')) {
          icon = loop.children[0]
        }
      }
      if (target.classList.contains('open')) {
        target.classList.remove('open')
        icon.classList.add('fa-plus')
        icon.classList.remove('fa-minus')
      } else {
        target.classList.add('open')
        icon.classList.remove('fa-plus')
        icon.classList.add('fa-minus')
      }

      // this.subToggle = !this.subToggle
    },
    async setRateListDefault () {
      for (const loop of this.gameListDefault) {
        for (const item of loop.child) {
          const model = lodash.cloneDeep(this.rateModel)
          model.gameType = item.code
          model.gameName = item.codeName
          model.groupCode = item.groupCode
          model.loop = Number(model.maxPointRate) / this.rateIncrease
          this.rateList.push(model)
        }
      }
    },
    async setMaxRate () {
      const rlist = lodash.cloneDeep(this.rateList)
      const upperRate = lodash.cloneDeep(this.upperRate)
      // console.log(upperRate);
      upperRate.forEach(item => {
        rlist.forEach((item2, index) => {
          if (item.gameType === item2.gameType) {
            item2.maxRate = item.maxRate
            return false
          }
        })
      })
      // console.log(rlist);
      this.rateList = rlist
    },
    async getPartnerLevels () {
      const params = {
        masterCode: 'partner',
        groupCode: 'partner'
      }
      const res = await partnerLevels(params)
      this.partnerLevel = res.data.partnerLevels
      this.selectPartnerLevel = this.partnerLevel.code
    }
  },
  watch: {
    async 'joinData.recommenderId' () {
      this.batchRate_point = '0.00'
      this.batchRate_lose = '0.00'
      const recommenderId = this.joinData.recommenderId

      if (recommenderId) {
        this.getTopRate()
      }

      // const params = {
      //   memId: recommenderId
      // }
      // await getMaxRateByRecommendId(params).then(result => {
      //   if (result.resultCode === '0') {
      //     this.rateMaxMinList = result.data.rateMaxMinList
      //
      //     this.rateList.forEach((rate, idx) => {
      //       rate.loseRate = '0.00'
      //       rate.Poinnt = '0.00'
      //       const gameType = rate.gameType
      //       this.rateMaxMinList.forEach(item => {
      //         const vendorCode = item.vendorCode
      //         if (gameType === vendorCode) {
      //           Object.assign(rate, item)
      //         }
      //       })
      //     })
      //   }
      // })
    },
    async selectPartnerLevel () {
      this.batchRate_point = '0.00'
      this.batchRate_lose = '0.00'
      if (this.selectPartnerLevel) {
        await this.getPartnerMemIds()

        const recommenderId = this.joinData.recommenderId

        const params = {
          memId: recommenderId
        }
        await getMaxRateByRecommendId(params).then(result => {
          if (result.resultCode === '0') {
            this.rateMaxMinList = result.data.rateMaxMinList

            this.rateList.forEach((rate, idx) => {
              rate.loseRate = '0.00'
              rate.pointRate = '0.00'
              const gameType = rate.gameType
              this.rateMaxMinList.forEach(item => {
                const vendorCode = item.vendorCode
                if (gameType === vendorCode) {
                  Object.assign(rate, item)
                }
              })
            })
          }
        })
      }
    },
    gameList: {
      handler (value) {
        this.setRateListDefault(value)
      }
    },
    upperRate: {
      handler (upperRate) {
        if (upperRate.length !== 0) {
          this.setMaxRate()
        }
      }
    }
  },
  computed: {
    gameList () {
      return this.$store.getters.gameList
    }
  },
  async created () {
    this.emitter.emit('Loading', true)

    this.siteInfo = await this.getSiteInfo()
    this.joinData.siteId = this.siteInfo.siteId
    await this.setGameList()
    await this.getPartnerLevels()

    await this.getBank()
    await this.getLevels()

    const query = this.$route.query
    if (query) {
      if (query.partnerLevel) {
        this.selectPartnerLevel = query.partnerLevel
      }

      if (query.partnerId) {
        this.joinData.recommenderId = query.partnerId
        this.findRecommenderId = query.partnerId
      }
    }

    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.idsch{display: flex; align-items: center; gap: 5px;margin-top: 5px;}
.main-contents-wrapper {width: 700px;}
.wa {width: auto !important;min-width: 90px;}
.w33 {width: 40px !important; padding: 5px 10px;}
.ml20 {margin-left: 20px;border-left: 1px solid #b7b7b7;padding-left: 10px;}
.total-info-container {margin-left: auto;margin-bottom: 0;}
.rateController>div {display: flex;align-items: center;gap: 10px;}
.toggle-text {display: none;}
.toggle-wrap {justify-content: left;}
table {
  width: 100%;
}
.content-wrap {
  margin-bottom: 40px;
}

.content {
  font-size: 0.75em;
}
.content td {
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
  border-bottom: solid 1px #ddd;
}
.input-title {
  font-weight: 700;
  text-align: left;
  width: 9%;
}
.input-value {
  width: 20%;
}
.inner {
  display: flex;
  align-items: center;
}
.mainId {
  margin-right: 5px;
}
.innerWrap {
  padding: 4px 10px;
  height: 35px;
  max-height: 35px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  border-bottom: solid 1px #ddd;
}
/* 라디오 */
.box-ui-radio {
  font-size: 1em;
  display: flex;
  justify-content: center;
  margin-right: 10em;
}
.box-ui-radio:last-child {
  margin-right: 0;
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  display: flex;
  align-items: center;
}
input[type="radio"] + label > .circle {
  display: block;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  border: 2px solid #394357;
  border-radius: 100%;
  margin-right: 5px;
}
input[type="radio"]:checked + label > .circle {
  border: 5px solid #394357;
}

/* 체크박스 */
.box-ui-check {
  font-size: 1em;
  display: flex;
  justify-content: center;
}
input[type="checkBox"] {
  display: none;
}
input[type="checkbox"] + label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
input[type="checkbox"] + label > i {
  border: 2px solid #394357;
  background: #fff;
  border-radius: 5px;
  padding: 2px;
  font-size: 10px;
  color: #fff;
  margin-right: 4px;
}
input[type="checkbox"]:checked + label > i {
  background: #394357;
}

.hidenContent {
  display: none;
  align-items: center;
  width: fit-content;
}
.hidenContent.show {
  display: flex;
}
.subContent-wrapper {
  width: 100%;
  height: auto;
  max-height: unset;
  padding: 2px 5px;
}
.subContent-wrap {
  width: 100%;
}
.subContent-wrap .subTitle {
  font-size: 1.2em;
  font-weight: 800;
  background: #e9a198;
  color: #fff;
  padding: 8px 10px;
  position: relative;
}
.subContent {
  max-height: 0;
  overflow: hidden;
}
.subContent.open {
  padding: 5px 5px;
  max-height: unset;
}
.subContent th,
.subContent td {
  border: 1px solid #fff;
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
}
.subContent th {
  background: #ffe5de;
  color: #000;
  font-weight: 700;
}
th.subTitleHead {
  background: transparent;
  color: #000;
  text-align: left;
  border-bottom: solid 1px #ddd;
}
.subTable-title {
  background: #ffe5de;
  font-weight: 800;
  padding: 8px 8px;
}
.btn-subConShow {
  position: absolute;
  border: 2px solid #df4946;
  border-radius: 3px;
  padding: 4px;
  background: #df4946;
  color: #fff;
  font-size: 0.8em;
  transform: translateY(-50%);
  top: 50%;
  right: 8px;
  cursor: pointer;
}
.btn-subConShow:hover {
  background: #fff;
  color: #df4946;
}
.textCenter {
  text-align: center;
  font-weight: 700;
  padding: 4px 0;
}
.remark {
  font-weight: 800;
  color: red;
}
.notice {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: red;
}
.notice i {
  font-weight: 800;
  font-size: 1.5em;
  margin-right: 10px;
}
.notice > span {
  font-size: 1em;
  font-weight: 800;
}
.s-content {
  border: 0;
  margin: 10px 10px 10px 22px;
}
/* title select */
.items .item:first-child {
  margin-right: 20px;
}
.item i {
  font-size: 14px;
  margin-right: 4px;
  color: #686868;
}
.total-info-wrap .item {
  display: flex;
  align-items: center;
}
.total-info-wrap .item img {
  margin: 0 10px 0 10px;
}
.item .toggle-wrap {
  width: 65px;
}
.title-select-wrap {
  font-size: 12px;
  margin: 5px;
  min-width: 75px;
  max-width: 75px;
  display: flex;
  flex-direction: column;
  border: 1px solid #afafaf;
}
.title-select-title {
  padding: 5px 4px;
  box-sizing: border-box;
  text-align: center;
  background: #db504d;
  color: #fff;
  font-weight: 700;
  border-bottom: 1px solid #afafaf;
}
.title-select-item {
  box-sizing: border-box;
  padding: 4px;
  font-size: 1em;
  width: 100%;
  border: 0;
}
.item-content input {
  width: 100%;
  max-width: 202px;
  border: 1px solid #353535;
}
.item-content select {
  border: 1px solid #353535;
}
.userInfo-select {
  font-weight: 500;
  padding: 2px 5px;
  height: 21px;
  width: 100%;
}
.s-content > .content {
  display: flex;
  font-size: 14px;
  height: 36px;
}
.s-content-wrap {
  max-height: 350px;
}
.s-content {
  flex: 0 0 15.4%;
}
.s-content > .title {
  margin-bottom: 0;
  /*background: #353535;*/
  white-space: nowrap;
  color: #fff;
}
.itemz .s-content {
  flex: 0 0 11.2%;
}
.itemz .s-content .content select {
  border-top: 0;
  border-radius: 0 0 6px 6px;
  height: 30px;
  text-align: center;
}
.set-switch:after {
  display: none;
}
.rollcount {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #9e9e9e;
  border-top: 0;
}
.rollcount button {
  color: #676767;
  font-weight: bold;
  font-size: 1.2em;
  background: #fff;
  padding: 0 5px;
}
.rollcount span {
  font-weight: bold;
  color: #212121;
  font-size: 1em;
}
textarea {
  width: 100%;
  min-height: 160px;
  resize: none;
  box-sizing: border-box;
  font-size: 15px;
}
.textarea-container {
  display: flex;
  align-items: stretch;
  height: calc(100% - 44px);
}
.center button {
  width: 65px;
  height: 22px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #808080;
  background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);
  color: #252930;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
   display: flex;
   align-items: center;
   justify-content: center;
}
.rollcount > input[type="number"] {
  width: 85px;
  text-align: center;
  border-top: 0;
  border-bottom: 0;
  border-right: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
}
.fc-max {
  color: #ff5858 !important;
  font-size: 0.9em;
  font-weight: 900;
}
/*usecreate*/
.allwrap {margin: 0 17px;}
.mt-20 {margin-top: -20px;}
.w50 { width: 50px; }
.w80 { width: 80px; }
.tab-item:hover { background: #eb7a3f; color: #fff;}
.tab-item.active {background: #eb7a3f;}
.allwrap {
  max-width: 1440px;
}
.allwrap > div {
  display: flex;
  gap: 20px;
}
.allwrap > div.center {
  justify-content: center;
}
.content_wrap{
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  width: calc(50% - 20px);
  padding: 13px;
  background-color: #fff;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #b1b1b1;
}
.content_wrap table {
  font-size: 12px;
  color: #585963;
}
.content_wrap table select {
  border: 1px solid #b1b1b1;
}
.content_wrap table thead{
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
  border: solid 1px #b1b1b1;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #3d3d3d;
}
.content_wrap table th {
  padding: 7px 14px;
  text-align: left;
}
.content_wrap table td {
  padding: 4px 14px;
  border: 1px solid #b1b1b1;
  background-color: #fff;
}
input[type="text"], input[type="password"] {
  width: 186px;
}
select.w_half {
  width: 90px !important;
}
select.w_full {
  width: 186px !important;
}
.w_half:nth-child(1), .w_full:nth-child(1) {
  margin-right: 5px;
}
.setTab {
  display: flex;
}
.setTab li {
  cursor: pointer;
  width: 224px;
  height: 40px;
  padding: 0 15px;
  color: #bebebe;
  border-radius: 3px 3px 0 0;
  border: solid 1px #d5d5d5;
  background-color: #fff;
  box-sizing: border-box ;
  display: flex;
  align-items: center;
}
.setTab li.active {
  color: #000;
  background-color: #f9f9f9;
  border-top: 3px solid #eb7a3f;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.16);
}
.gameSetWrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background: #fff;
}
.gameSet {
  width: 33.33%;
  display: flex;
}
.gameSet > * {
  font-size: 12px;
  color: #585963;
  white-space: nowrap;
  padding: 10px 13px;
  box-sizing: border-box;
  border: 1px solid #b1b1b1;
  background: #fff;
}
.gameSet > *:nth-child(1) {
  width: 70%;
  display: flex;
  align-items: center;
}
.gameSet > *:nth-child(2) {
  width: 30%;
  border-left: 0;
  border-right: 0;
}
.gameSet:nth-child(n+4) > * {
  border-top: 0;
}
.gameSet:nth-child(3n) > *:nth-child(2) {
  border-right: 1px solid #d5d5d5;
}
.gameSet:last-child > *:nth-child(2) {
  position: relative;
}
.gameSet:last-child > *:nth-child(2)::after {
  content: "";
  position: absolute;
  right:-1px;
  height: 41px;
  width: 1px;
  display: inline-block;
  background: #d5d5d5;
}
.rateSetWrap {
  display: flex;
  width: 100%;
  background: #fff;
}
.rateSetWrap .rateL {
  width: 33%;
}
.rateSetWrap .rateR {
  width: 67%;
}
.rateL > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-right: 0;
}
.rateL > .btn-layout {
  padding: 3px 7px;
  color: #fff;
}
.btn-range {
  background: #6f7172;
}
.btn-min {
  border-radius: 3px 0 0 3px;
}
.btn-max {
  border-radius: 0 3px 3px 0;
}
.btn-apply {
  background: #eb7a3f;
  border-radius: 3px;
}
.rate-content {
  border: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.rate-content > .content {
  display: flex;
  font-size: 14px;
  height: 36px;
}
.rate-content > div {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  width: 33.33%;
  padding: 5px 12px;
  font-size: 14px;
  height: 48px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.rate-content .rollcount {
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
}
.rate-content > div:nth-child(2) {
  border-left:0;
  border-right:0;
}
.minibtn {width: 66.66% !important;margin: 0 auto;display: flex;align-items: center;justify-content: center;}
.minibox {z-index: 100;position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%);width: auto !important;height: auto !important;padding: 40px 40px 30px !important;background: #fff;flex-direction: column;gap: 15px;box-sizing: border-box;border: 1px solid #d5d5d5;white-space: nowrap;display: flex;align-items: center;}
.minibox table {height: 100%;}
.minibox td > div {display: flex;align-items: center;gap: 10px;}
.minibox .close {position: absolute;top: 10px;right: 15px;font-size: 20px;cursor: pointer;}
.minibox button.save {padding: 10px 40px;font-size: 16px;}
.minibox .error {border: 1px #944747 solid;}
.btn-min i {margin-left: 5px;color: #a4bbfd;}
.btn-max i {margin-left: 5px;color: #ffa06e;}
.btnwrap {display: flex;gap: 5px;}
</style>
